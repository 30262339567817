import Actions from "pages/actions/list";

import { lazy, Suspense } from "react";
import { Outlet } from "react-router";
import { AuthGuard } from "../../auth/guard";
import { LoadingScreen } from "../../components/loading-screen";
import DashboardLayout from "../../layouts/dashboard/layout";

//-- pages
const UserListPage = lazy(() => import("../../pages/user/list"));
const ListKeyValues = lazy(() => import("../../pages/keyValue/list-keys"));
const ManageAccess = lazy(() => import("../../pages/access/manage-access"));
const ActionExecutionsList = lazy(() => import("../../pages/action-executions/action-executions"));
const ActionExecutionLogs = lazy(() => import("../../pages/action-executions/action-execution-logs"));
const ListConfigs = lazy(() => import("../../pages/configs/list-configs"));
const AIChat = lazy(() => import("../../pages/ai/chat"));

export const dashboardRoutes = [
    {
        path: "dashboard",
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            {
                path: "config",
                element: <ListConfigs />,
            },
            {
                path: "keyvalues",
                element: <ListKeyValues />,
            },
            {
                path: "ai",
                element: <AIChat />,
            },
            {
                path: "access",
                element: <ManageAccess />,
            },
            {
                path: "actions",
                element: <Actions />,
            },
            {
                path: "users",
                element: <UserListPage />,
            },
            {
                path: "logs",
                element: <ActionExecutionsList />,
            },
            { path: "logs/:id", element: <ActionExecutionLogs /> },
        ],
    },
];
