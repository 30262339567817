import { LazyMotion, m } from "framer-motion";

// ----------------------------------------------------------------------
import feature from "./features";

type Props = {
    children: React.ReactNode;
};

function MotionLazy({ children }: Props) {
    return (
        <LazyMotion strict features={feature}>
            <m.div style={{ height: "100%" }}> {children} </m.div>
        </LazyMotion>
    );
}

export default MotionLazy;
