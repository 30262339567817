import { combineReducers } from "redux";
import chatReducer from "./slices/chat";
import authReducer from "./slices/auth";
import configReducer from "./slices/config";
import accessReducer from "./slices/access";
import usersReducer from "./slices/users";
import keyValueReducer from "./slices/keyValue";
import actionExecutionReducer from "./slices/action-execution";

import storage from "redux-persist/lib/storage";

export const rootPersistConfig = {
    key: "root",
    storage,
    keyPrefix: "redux-",
    whitelist: [],
};

export const rootReducer = combineReducers({
    chat: chatReducer,
    config: configReducer,
    auth: authReducer,
    access: accessReducer,
    users: usersReducer,
    keyValue: keyValueReducer,
    actionExecution: actionExecutionReducer,
});
