import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthUserType } from "../../auth/types";
import { IError } from "../../types/error";

type userPayload = {
    user: AuthUserType | null;
};

interface IAuthState {
    initialized: boolean;
    loading: boolean;
    busy: boolean;
    modalIsOpen: boolean;
    authenticated: boolean;
    payload: userPayload;
    Errors: IError[] | null;
}

const initialState: IAuthState = {
    modalIsOpen: false,
    authenticated: false,
    payload: { user: null },
    initialized: false,
    loading: true,
    busy: false,
    Errors: null,
};

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        InitUser: (state, action: PayloadAction<userPayload>) => {
            state.payload = action.payload;
            state.initialized = true;
            state.loading = false;
            state.authenticated = action.payload.user != null;
        },
        setAuthModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.modalIsOpen = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        setErrors: (state, action: PayloadAction<IError[] | null>) => {
            state.Errors = action.payload;
        },
        setBusy: (state, action: PayloadAction<boolean>) => {
            state.busy = action.payload;
        },
        setInitialized: (state, action: PayloadAction<boolean>) => {
            state.initialized = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { InitUser, setAuthModalIsOpen, setLoading, setErrors, setInitialized, setBusy } = slice.actions;
