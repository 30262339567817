// routes
import { paths } from "../../routes/paths";
//
import { Navigate, useLocation } from "react-router";
import { InitFailed } from "../../components/init-failed/init-failed";
import { LoadingScreen } from "../../components/loading-screen";
import { UseAuth } from "../hooks";

// ----------------------------------------------------------------------

type AuthGuardProps = {
    children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
    const { authenticated, initialized, resetAuth, busy, loading, Errors } = UseAuth();

    const location = useLocation();

    const AuthUrl = () => {
        const searchParams = new URLSearchParams({ returnTo: location.pathname }).toString();
        return `${paths.auth.login}?${searchParams}`;
    };

    if (Errors && Errors.length > 0) return <InitFailed errors={Errors} loading={busy} onRetry={resetAuth} />;

    if (!initialized || loading) return <LoadingScreen />;

    if (!authenticated) return <Navigate to={AuthUrl()} />;

    return <>{children}</>;
}
