import { m } from "framer-motion";
// @mui
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
// routes
import { useRouter } from "../../routes/hook";
// components
import toast from "react-hot-toast";
import { UseAuth } from "../../auth/hooks";
import { varHover } from "../../components/animate";
import CustomPopover, { usePopover } from "../../components/custom-popover";
import { DateLabel } from "../../components/date/date-label";
import { TimeLabel } from "../../components/date/time-label";

// ----------------------------------------------------------------------

const OPTIONS = [
    {
        label: "Home",
        linkTo: "/",
    },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const router = useRouter();

    const { logout, user } = UseAuth();
    const displayName = `${user?.firstName} ${user?.lastName}`;
    const popover = usePopover();

    const handleLogout = async () => {
        try {
            logout();
            popover.onClose();
            router.replace("/");
        } catch (error) {
            toast.error(error);
        }
    };

    const handleClickItem = (path: string) => {
        popover.onClose();
        router.push(path);
    };

    return (
        <>
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                onClick={popover.onOpen}
                sx={{
                    width: 40,
                    height: 40,
                    background: (theme) => alpha(theme.palette.grey[500], 0.08),
                    ...(popover.open && {
                        background: (theme) =>
                            `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    }),
                }}>
                <Avatar
                    src={user?.photoURL}
                    sx={{
                        width: 36,
                        height: 36,
                        border: (theme) => `solid 2px ${theme.palette.background.default}`,
                    }}
                />
            </IconButton>

            <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
                <Box sx={{ p: 2, pb: 1.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {user?.username}
                    </Typography>

                    <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                        <Stack flexDirection="row" justifyContent="space-between">
                            <DateLabel value={user?.lastLoginDate} />
                            <TimeLabel value={user?.lastLoginDate} />
                        </Stack>
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: "dashed" }} />

                <Stack sx={{ p: 1 }}>
                    {OPTIONS.map((option) => (
                        <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: "dashed" }} />

                <MenuItem onClick={handleLogout} sx={{ m: 1, fontWeight: "fontWeightBold", color: "error.main" }}>
                    Logout
                </MenuItem>
            </CustomPopover>
        </>
    );
}
