import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IError } from "../../types/error";
import { IActionExecution } from "types/action-execution";
import { IActionLog } from "types/action-log";
import { IAutonomousSystem } from "types/autonomous-system";

interface IActionExecutionState {
    initialized: boolean;
    logEnabled: boolean;
    loading: boolean;
    busy: boolean;
    actions: IActionExecution[];
    autonomousSystems: IAutonomousSystem[];
    Errors: IError[] | null;
}

const initialState: IActionExecutionState = {
    logEnabled: false,
    actions: [],
    autonomousSystems: [],
    initialized: false,
    loading: true,
    busy: false,
    Errors: null,
};

const slice = createSlice({
    name: "actionExecution",
    initialState,
    reducers: {
        init: (
            state,
            action: PayloadAction<{ actions: IActionExecution[]; autonomousSystems: IAutonomousSystem[]; logEnabled: boolean }>
        ) => {
            state.actions = action.payload.actions;
            state.autonomousSystems = action.payload.autonomousSystems;
            state.logEnabled = action.payload.logEnabled;

            state.initialized = true;
            state.loading = false;
            state.Errors = null;
        },
        initActionLogs: (state, action: PayloadAction<{ actionsExecution: IActionExecution; actionLogs: IActionLog[] }>) => {
            if (!state.actions.some((x) => x.id === action.payload.actionsExecution.id))
                state.actions = [...state.actions, { ...action.payload.actionsExecution, actionLogs: action.payload.actionLogs }];
            else
                state.actions = state.actions.map((x) =>
                    x.id === action.payload.actionsExecution.id ? { ...x, actionLogs: action.payload.actionLogs } : x
                );
        },
        syncActionExecution: (state, action: PayloadAction<IActionExecution>) => {
            if (!state.actions.some((x) => x.id === action.payload.id)) {
                state.actions = [action.payload, ...state.actions];
            } else {
                state.actions = state.actions.map((x) => (x.id === action.payload.id ? { ...action.payload, modifiedAt: new Date() } : x));
            }

            state.initialized = true;
            state.loading = false;
            state.Errors = null;
        },

        deleteActionExecution: (state, action: PayloadAction<number>) => {
            state.actions = state.actions.filter((x) => x.id !== action.payload);
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setErrors: (state, action: PayloadAction<IError[] | null>) => {
            state.Errors = action.payload;
        },
        setBusy: (state, action: PayloadAction<boolean>) => {
            state.busy = action.payload;
        },
        setInitialized: (state, action: PayloadAction<boolean>) => {
            state.initialized = action.payload;
        },
        setLogEnabled: (state, action: PayloadAction<boolean>) => {
            state.logEnabled = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    init,
    initActionLogs,
    syncActionExecution,
    deleteActionExecution,
    setLoading,
    setErrors,
    setLogEnabled,
    setInitialized,
    setBusy,
} = slice.actions;
