import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRole } from "../../types/role";
import { IError } from "../../types/error";
import { IAction } from "types/action";
import { IPermission } from "types/permission";

interface IRoleState {
    //setup variables
    initialized: boolean;
    loading: boolean;
    busy: boolean;
    Errors: IError[] | null;
    //data list variables
    roles: IRole[];
    actions: IAction[];
    permissions: IPermission[];
}

const initialState: IRoleState = {
    //setup variables
    initialized: false,
    loading: false,
    busy: false,
    Errors: null,
    //data list variables
    roles: [],
    actions: [],
    permissions: [],
};

const slice = createSlice({
    name: "permission-roles",
    initialState,
    reducers: {
        SetupAccessVariables: (state, action: PayloadAction<{ roles: IRole[]; actions: IAction[]; permissions: IPermission[] }>) => {
            //data list variables
            state.roles = action.payload.roles;
            state.actions = action.payload.actions;
            state.permissions = action.payload.permissions;
            //setup variables
            state.initialized = true;
            state.loading = false;
            state.Errors = null;
        },
        //-- roles
        SyncRole: (state, action: PayloadAction<IRole>) => {
            if (!state.roles.some((x) => x.id === action.payload.id)) {
                state.roles = [...state.roles, action.payload];
            } else {
                state.roles = state.roles.map((x) => (x.id === action.payload.id ? { ...action.payload } : x));
            }

            state.initialized = true;
            state.loading = false;
            state.Errors = null;
        },
        RemoveRole: (state, action: PayloadAction<IRole>) => {
            state.roles = state.roles.filter((x) => x.id !== action.payload.id);
        },

        //-- actions
        InitActions: (state, action: PayloadAction<IAction[]>) => {
            state.actions = action.payload;
            state.initialized = true;
            state.loading = false;
            state.Errors = null;
        },
        SyncAction: (state, action: PayloadAction<IAction>) => {
            if (!state.actions.some((x) => x.id === action.payload.id)) {
                state.actions = [...state.actions, action.payload];
            } else {
                state.actions = state.actions.map((x) => (x.id === action.payload.id ? { ...action.payload } : x));
            }

            state.initialized = true;
            state.loading = false;
            state.Errors = null;
        },
        RemoveAction: (state, action: PayloadAction<IAction>) => {
            state.actions = state.actions.filter((x) => x.id !== action.payload.id);
        },

        //-- permissions
        RemovePermission: (state, action: PayloadAction<IPermission>) => {
            state.permissions = state.permissions.filter(
                (x) => !(x.roleId === action.payload.roleId && x.actionId === action.payload.actionId)
            );
        },
        SyncPermission: (state, action: PayloadAction<IPermission>) => {
            if (!state.permissions.some((x) => x.actionId === action.payload.actionId && x.roleId === action.payload.roleId)) {
                state.permissions = [...state.permissions, action.payload];
            } else {
                state.permissions = state.permissions.map((x) =>
                    action.payload.actionId && x.roleId === action.payload.roleId ? { ...action.payload } : x
                );
            }
        },

        //setup functions
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setErrors: (state, action: PayloadAction<IError[] | null>) => {
            state.Errors = action.payload;
        },
        setBusy: (state, action: PayloadAction<boolean>) => {
            state.busy = action.payload;
        },
        setInitialized: (state, action: PayloadAction<boolean>) => {
            state.initialized = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    SetupAccessVariables,

    //-- roles
    SyncRole,
    RemoveRole,

    //-- actions
    InitActions,
    SyncAction,
    RemoveAction,

    //-- permission
    RemovePermission,
    SyncPermission,

    //setup functions
    setLoading,
    setErrors,
    setInitialized,
    setBusy,
} = slice.actions;
