import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserProfile } from "types/user";
import { IError } from "../../types/error";

interface IUserState {
    initialized: boolean;
    loading: boolean;
    busy: boolean;
    usersList: IUserProfile[];
    Errors: IError[] | null;
}

const initialState: IUserState = {
    usersList: [],
    initialized: false,
    loading: true,
    busy: false,
    Errors: null,
};

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        InitUsers: (state, action: PayloadAction<IUserProfile[]>) => {
            state.usersList = action.payload;
        },

        RemoveUsers: (state, action: PayloadAction<number>) => {
            state.usersList = state.usersList.filter((user) => user.id !== action.payload);
        },

        SaveUsers: (state, action: PayloadAction<IUserProfile>) => {
            const exists = state.usersList.some((user) => user.id === action.payload.id);

            if (exists) {
                state.usersList = state.usersList.map((user) => (user.id === action.payload.id ? action.payload : user));
            } else {
                state.usersList = [...state.usersList, action.payload];
            }
        },

        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        setErrors: (state, action: PayloadAction<IError[] | null>) => {
            state.Errors = action.payload;
        },
        setBusy: (state, action: PayloadAction<boolean>) => {
            state.busy = action.payload;
        },
        setInitialized: (state, action: PayloadAction<boolean>) => {
            state.initialized = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { InitUsers, SaveUsers, RemoveUsers, setLoading, setErrors, setInitialized, setBusy } = slice.actions;
