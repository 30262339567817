import { Outlet } from "react-router-dom";
import { GuestGuard } from "../../auth/guard";
import { lazy } from "react";

import AuthModernCompactLayout from "../../layouts/auth/modern-compact";
const LoginPage = lazy(() => import("../../pages/auth/login"));

export const authRoutes = [
    {
        path: "auth",
        element: (
            <GuestGuard>
                <Outlet />
            </GuestGuard>
        ),
        children: [
            {
                path: "login",
                element: (
                    <AuthModernCompactLayout>
                        <LoginPage />
                    </AuthModernCompactLayout>
                ),
            },
        ],
    },
];
