import { Box } from "@mui/material";
import { format } from "date-fns";

type DateLabelProps = {
    value: Date | Number;
    sx?: any;
};

export const DateLabel: React.FC<DateLabelProps> = ({ value, sx }) => {
    if (typeof value === "number") value = new Date((value as number) / 1e12);

    return <Box sx={sx ? { ...sx } : {}}>{format(new Date(value.toString()), "dd MMM yyyy")}</Box>;
};
