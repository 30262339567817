import { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../redux/store";
import { useAuthenticationService } from "../../api/authentication/authentication-service-hook";
import { getAccessToken, setSession } from "../../api/utils";
import { InitUser, setAuthModalIsOpen, setBusy, setErrors, setInitialized, setLoading } from "../../redux/slices/auth";
import { AuthUserType } from "../types";
import { IError } from "../../types/error";

export const UseAuth = () => {
    const dispatch = useDispatch();
    const authService = useAuthenticationService();

    const { t } = useTranslation();
    const { Errors, busy, initialized, loading, modalIsOpen, payload } = useSelector((state) => state.auth);

    const resetState = useCallback(() => {
        setSession(null);
        dispatch(
            InitUser({
                user: null,
            })
        );
    }, [dispatch]);

    const setUserSession = useCallback(
        (result: any) => {
            setSession(result.token);
            const { lastLoginDate, role, username } = result;
            const user = { lastLoginDate, role, username };
            dispatch(InitUser({ user }));
            dispatch(setErrors(null));
        },
        [dispatch]
    );

    const initUserInfo = useCallback(async () => {
        await authService
            .initUserInfo()
            .then((result) => {
                dispatch(setErrors(null));
                setUserSession(result);
            })
            .catch((errs) => {
                if (errs && errs.length > 0 && !errs.some((err: any) => err.code === 403)) dispatch(setErrors(errs));
            })
            .finally(() => {
                dispatch(setLoading(false));
                dispatch(setBusy(false));
            });
    }, [setUserSession, dispatch, authService]);

    const resetAuth = async () => {
        dispatch(setBusy(true));
        await initUserInfo();
    };

    const initialize = useCallback(async () => {
        try {
            const token = getAccessToken();
            setSession(token);
            await initUserInfo();
            return dispatch(setInitialized(true));
        } catch (error) {
            toast.error(error);
            resetState();
        }
    }, [initUserInfo, resetState, dispatch]);

    useEffect(() => {
        if (initialized || Errors) return;
        initialize();
    }, [initialized, Errors, initialize]);

    const login = async (username: string, password: string, onError: (errors: IError[]) => void) => {
        await authService
            .login(username, password)
            .then((result: any) => {
                toast.success(t("login-successful"));
                setUserSession(result);
            })
            .catch((err) => onError(err));
    };

    const register = async (username: string, password: string, onError: (errors: IError[]) => void) => {
        await authService
            .register(username, password)
            .then(setUserSession)
            .catch((err) => onError(err));
    };

    const updateUserInfo = async (user: AuthUserType) => setUserSession({ user: user, token: getAccessToken() });

    const toggleAuthModalStatus = () => {
        dispatch(setAuthModalIsOpen(!modalIsOpen));
    };

    return {
        login,
        register,
        logout: resetState,
        updateUserInfo,
        toggleAuthModalStatus,
        resetAuth,
        user: payload.user,
        authenticated: payload.user !== null,
        unauthenticated: payload.user === null,
        initialized,
        modalIsOpen,
        loading,
        busy,
        Errors,
    };
};
