import { Helmet } from "react-helmet-async";

export default function Actions() {
    return (
        <>
            <Helmet>
                <title> Dashboard: Actions</title>
            </Helmet>

            <div>Actions view</div>
        </>
    );
}
