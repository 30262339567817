import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IConfig } from "../../types/config";
import { IError } from "../../types/error";
import { V2rayConfig } from "../../types/v2ray-config";
import { IkeyValue } from "types/keyValue";

interface IConfigState {
    initialized: boolean;
    loading: boolean;
    busy: boolean;
    configs: IConfig[];
    remarkPrefix: IkeyValue;
    Errors: IError[] | null;
}

const initialState: IConfigState = {
    configs: [],
    remarkPrefix: { key: "REMARKPREFIX", value: "" },
    initialized: false,
    loading: true,
    busy: false,
    Errors: null,
};

const slice = createSlice({
    name: "config",
    initialState,
    reducers: {
        initConfigs: (state, action: PayloadAction<IConfig[]>) => {
            state.configs = action.payload;
            state.initialized = true;
            state.loading = false;
            state.Errors = null;
        },
        syncConfig: (state, action: PayloadAction<IConfig>) => {
            if (!state.configs.some((x) => x.uuid === action.payload.uuid)) {
                state.configs = [{ ...action.payload, modifiedAt: new Date(), createdAt: new Date() }, ...state.configs];
            } else {
                state.configs = state.configs.map((x) =>
                    x.uuid === action.payload.uuid ? { ...action.payload, modifiedAt: new Date() } : x
                );
            }

            state.initialized = true;
            state.loading = false;
            state.Errors = null;
        },
        initRemarkPrefix: (state, action: PayloadAction<IkeyValue>) => {
            state.remarkPrefix = action.payload;
        },
        deleteConfig: (state, action: PayloadAction<string>) => {
            state.configs = state.configs.filter((x) => x.uuid !== action.payload);
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setErrors: (state, action: PayloadAction<IError[] | null>) => {
            state.Errors = action.payload;
        },
        setBusy: (state, action: PayloadAction<boolean>) => {
            state.busy = action.payload;
        },
        setInitialized: (state, action: PayloadAction<boolean>) => {
            state.initialized = action.payload;
        },
        initV2rayConfig: (state, action: PayloadAction<{ uuid: string; v2rayConfig: V2rayConfig }>) => {
            if (state.configs.some((x) => x.uuid === action.payload.uuid))
                state.configs = state.configs.map((x) =>
                    x.uuid === action.payload.uuid ? { ...x, v2rayConfig: action.payload.v2rayConfig } : x
                );
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { initConfigs, initRemarkPrefix, initV2rayConfig, syncConfig, deleteConfig, setLoading, setErrors, setInitialized, setBusy } =
    slice.actions;
