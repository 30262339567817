import axios from "../utils/axios";
import { IApiRequest } from "../types/api-request";
import { IServerResponse } from "../types/server-response";
import { TranslateErrorMessages } from "./error-codes";

const withCredentials = true;
const headers = { "Content-Type": "application/json" };

const callAxios = <T>(req: IApiRequest) => {
    return axios.request<T>({
        url: `${window.location.href.includes("localhost") ? "http://localhost:8787/api/" : "/api/"}${req.url}`,
        data: req.data,
        method: req.method,
        responseType: req.responseType,
        withCredentials,
        headers,
    });
};

const executeRequest = (req: IApiRequest) =>
    new Promise((resolve, reject) => {
        callAxios<IServerResponse>(req)
            .then(({ data }) => {
                if (data.success) resolve(data.result);
                else return reject(data.errors);
            })
            .catch((cth) => reject(cth.errors));
    });

const makeblobUrl = async (imageUrl: string, mimeType: string) =>
    new Promise(async (resolve, reject) => {
        try {
            callAxios<any>({ method: "get", url: imageUrl, responseType: "arraybuffer" })
                .then((Response) => {
                    const serverResponse = Response.data;
                    const contentType = Response.headers["content-type"];
                    if (contentType.includes("application/json")) {
                        const text = new TextDecoder().decode(serverResponse.data);
                        var json = JSON.parse(text);
                        reject(json.errors);
                    } else {
                        var blob = new Blob([Response.data], { type: mimeType || contentType });
                        resolve(window.URL.createObjectURL(blob));
                    }
                })
                .catch((err) => {
                    if (err.response?.status === 404) reject(TranslateErrorMessages(404));
                    else reject(TranslateErrorMessages(502));
                });
        } catch (error) {
            reject(TranslateErrorMessages(502));
        }
    });

export const get = (url: string) => executeRequest({ method: "get", url, responseType: "json" });

export const post = (url: string, data?: {}) => executeRequest({ method: "post", url, data, responseType: "json" });

export const put = (url: string, data: {}) => executeRequest({ method: "put", url, data, responseType: "json" });

export const remove = (url: string) => executeRequest({ method: "delete", url, responseType: "json" });

export const blob = (url: string, mimeType: string) => makeblobUrl(url, mimeType);
