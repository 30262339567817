import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IkeyValue } from "types/keyValue";
import { IError } from "../../types/error";

interface IUserState {
    initialized: boolean;
    loading: boolean;
    busy: boolean;
    keys: IkeyValue[];
    Errors: IError[] | null;
}

const initialState: IUserState = {
    keys: [],
    initialized: false,
    loading: true,
    busy: false,
    Errors: null,
};

const slice = createSlice({
    name: "keyValue",
    initialState,
    reducers: {
        InitkeyValues: (state, action: PayloadAction<IkeyValue[]>) => {
            state.keys = action.payload;
            setErrors(null);
        },

        RemoveKey: (state, action: PayloadAction<string>) => {
            state.keys = state.keys.filter((obj) => obj.key !== action.payload);
            setErrors(null);
        },

        SaveKeyValues: (state, action: PayloadAction<IkeyValue>) => {
            const exists = state.keys.some((obj) => obj.key === action.payload.key);

            if (exists) {
                state.keys = state.keys.map((obj) => (obj.key === action.payload.key ? action.payload : obj));
            } else {
                state.keys = [...state.keys, action.payload];
            }
        },

        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        setErrors: (state, action: PayloadAction<IError[] | null>) => {
            state.Errors = action.payload;
        },
        setBusy: (state, action: PayloadAction<boolean>) => {
            state.busy = action.payload;
        },
        setInitialized: (state, action: PayloadAction<boolean>) => {
            state.initialized = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { InitkeyValues, SaveKeyValues, RemoveKey, setLoading, setErrors, setInitialized, setBusy } = slice.actions;
