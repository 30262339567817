import { Box, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type TProps = {
    children: ReactNode;
    sx?: SxProps<Theme>;
    append?: string;
};

export const T: React.FC<TProps> = ({ children, sx, append }) => {
    const { t } = useTranslation();
    if (typeof children === "string") {
        children = t(children);
    }

    return (
        <Box sx={sx}>
            {children}
            {append && <>&nbsp;{append}</>}
        </Box>
    );
};
