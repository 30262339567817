import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MotionLazy from "./components/animate/motion-lazy";
import { SettingsProvider } from "./components/settings";
import SnackbarProvider from "./components/snackbar/snackbar-provider";
import "./locales/i18n";
import ToasterProvider from "./providers/toaster-provider";
import ReduxProvider from "./redux/redux-provider";
import Router from "./routes/sections";
import ThemeProvider from "./theme";

function App() {
    return (
        <ReduxProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider
                    defaultSettings={{
                        themeMode: "dark", // 'light' | 'dark'
                        themeDirection: "ltr", //  'rtl' | 'ltr'
                        themeContrast: "default", // 'default' | 'bold'
                        themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
                        themeColorPresets: "orange", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                        themeStretch: false,
                    }}>
                    <ThemeProvider>
                        <MotionLazy>
                            <SnackbarProvider>
                                <ToasterProvider />
                                <Router />
                            </SnackbarProvider>
                        </MotionLazy>
                    </ThemeProvider>
                </SettingsProvider>
            </LocalizationProvider>
        </ReduxProvider>
    );
}

export default App;
