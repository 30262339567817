import { lazy } from "react";
import { Outlet } from "react-router-dom";

import CompactLayout from "../../layouts/compact/layout";
const Page500 = lazy(() => import("../../pages/errors/500"));
const Page403 = lazy(() => import("../../pages/errors/403"));
const Page404 = lazy(() => import("../../pages/errors/404"));

export const errorRoutes = [
    {
        element: (
            <CompactLayout>
                <Outlet />
            </CompactLayout>
        ),
        children: [
            { path: "500", element: <Page500 /> },
            { path: "404", element: <Page404 /> },
            { path: "403", element: <Page403 /> },
        ],
    },
];
