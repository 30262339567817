import { Box } from '@mui/material';
import { format } from 'date-fns';

type TimeLabelProps = {
  value: Date;
  sx?: any;
};

export const TimeLabel: React.FC<TimeLabelProps> = ({ value, sx }) => {
  return <Box sx={sx ? { ...sx } : {}}>{format(new Date(value), 'p')}</Box>;
};
