import { Navigate, useRoutes } from "react-router-dom";
import { authRoutes } from "../../routes/sections/auth";
import { dashboardRoutes } from "../../routes/sections/dashboard";
import { errorRoutes } from "../../routes/sections/errrors";
import { mainRoutes } from "../../routes/sections/main";

export default function Router() {
    return useRoutes([
        ...mainRoutes,
        ...authRoutes,
        ...dashboardRoutes,
        ...errorRoutes,

        { path: "*", element: <Navigate to="/404" replace /> },
    ]);
}
