import { LoginDTO } from "../../models/auth/login-dto";
import { RegisterDTO } from "../../models/auth/register-dto";
import { get, post, put } from "../api";
import Urls from "../end-points";

export const AuthApi = {
    initUserInfo: () => get(Urls.auth.userInfo),
    login: (data: LoginDTO) => post(Urls.auth.login, data),
    register: (data: RegisterDTO) => put(Urls.auth.register, data),
};
