import { IPermission } from "types/permission";

const controllers = {
    users: "users",
    configs: "configs",
    actionExecutions: "action-executions",
    keyValues: "keyValues",
    roles: "roles",
    actions: "actions",
    permissions: "permissions",
    auth: "auth",
    ai: "ai",
};

const Urls = {
    actionExecutions: {
        initLogs: `${controllers.actionExecutions}`,
        initLog: (id: number) => `${controllers.actionExecutions}/${id}`,
        logEnabled: `${controllers.actionExecutions}/log-enabled`,
    },
    configs: {
        initConfigs: `${controllers.configs}`,
        saveConfig: `${controllers.configs}`,
        deleteConfig: (uuid: string) => `${controllers.configs}/${uuid}`,
        initConfig: (uuid: string) => `${controllers.configs}/${uuid}`,
        initShareConfig: (uuid: string) => `${controllers.configs}/${uuid}/share`,
    },
    keyValues: {
        initKeyValues: `${controllers.keyValues}`,
        saveKeyValues: `${controllers.keyValues}`,
        deleteKeyValue: (key: string) => `${controllers.keyValues}/${key}`,
        initKeyValue: (key: string) => `${controllers.keyValues}/${key}`,
    },
    roles: {
        initRoles: `${controllers.roles}`,
        initRole: (id: string) => `${controllers.roles}/${id}`,
        saveRole: `${controllers.roles}`,
        deleteRole: `${controllers.roles}`,
    },
    actions: {
        initActions: `${controllers.actions}`,
        initAction: (id: string) => `${controllers.actions}/${id}`,
        saveAction: `${controllers.actions}`,
        deleteAction: (id: string) => `${controllers.actions}/${id}`,
    },
    permissions: {
        deletePermission: (permission: IPermission) => `${controllers.permissions}/${permission.actionId}/${permission.roleId}`,
        savePermission: `${controllers.permissions}`,
    },
    users: {
        initUsers: `${controllers.users}`,
        saveUser: `${controllers.users}`,
        removeUser: (id: number) => `${controllers.users}/${id}`,
    },
    ai: {
        sendMessageAction: `${controllers.ai}`,
        getConversationAction: (conversationId: string) => `${controllers.ai}/conversations/${conversationId}`,
        getConversationsAction: `${controllers.ai}/conversations`,
    },
    auth: {
        userInfo: `${controllers.auth}/me`,
        login: `${controllers.auth}`,
        register: `${controllers.auth}`,
    },
};
export default Urls;
