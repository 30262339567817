import { useMemo } from "react";
import { useLocales } from "../../locales";
// ----------------------------------------------------------------------
import SvgColor from "../../components/svg-color";
import { paths } from "../../routes/paths";
// OR
// <Iconify icon="fluent:mail-24-filled" />
// https://icon-sets.iconify.design/solar/
// https://www.streamlinehq.com/icons

const icon = (name: string) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
    // OR
    // <Iconify icon="fluent:mail-24-filled" />
    // https://icon-sets.iconify.design/solar/
    // https://www.streamlinehq.com/icons
);
const ICONS = {
    json: icon("ic_json"),
    logs: icon("ic_logs"),
    chat: icon("ic_chat"),
    mail: icon("ic_mail"),
    user: icon("ic_user"),
    file: icon("ic_file"),
    lock: icon("ic_lock"),
    tour: icon("ic_tour"),
    order: icon("ic_order"),
    label: icon("ic_label"),
    blank: icon("ic_blank"),
    kanban: icon("ic_kanban"),
    folder: icon("ic_folder"),
    banking: icon("ic_banking"),
    booking: icon("ic_booking"),
    invoice: icon("ic_invoice"),
    product: icon("ic_product"),
    calendar: icon("ic_calendar"),
    disabled: icon("ic_disabled"),
    external: icon("ic_external"),
    menuItem: icon("ic_menu_item"),
    ecommerce: icon("ic_ecommerce"),
    analytics: icon("ic_analytics"),
    dashboard: icon("ic_dashboard"),
};

// ----------------------------------------------------------------------

export function useNavData() {
    const { t } = useLocales();

    const data = useMemo(
        () => [
            // OVERVIEW
            // ----------------------------------------------------------------------
            {
                subheader: t("overview"),
                items: [{ title: t("app"), path: paths.dashboard.root, icon: ICONS.dashboard }],
            },

            // MANAGEMENT
            // ----------------------------------------------------------------------
            {
                subheader: t("management"),
                items: [
                    // CONFIG
                    { title: t("config"), path: paths.dashboard.config.root, icon: ICONS.ecommerce },
                    {
                        title: t("access"),
                        path: paths.dashboard.access.root,
                        icon: ICONS.file,
                    },
                    {
                        title: t("users"),
                        path: paths.dashboard.user.root,
                        icon: ICONS.user,
                    },
                    {
                        title: t("key-values"),
                        path: paths.dashboard.keyValue.root,
                        icon: ICONS.json,
                    },
                    {
                        title: t("logs"),
                        path: paths.dashboard.logs.root,
                        icon: ICONS.logs,
                    },
                ],
            },
            //AI ASSISTANSE
            {
                subheader: t("ai-assistanse"),
                items: [
                    // CHAT
                    {
                        title: t("chat"),
                        path: paths.dashboard.ai.root,
                        icon: ICONS.chat,
                    },
                ],
            },
        ],
        [t]
    );

    return data;
}
