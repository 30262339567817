import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { paths } from "../../routes/paths";
import { IError } from "../../types/error";
import { T } from "../text";

type InitFailedProps = {
    errors: IError[];
    loading: boolean;
    setError?: (errors: IError[] | null) => void;
    onRetry: VoidFunction;
};

export const InitFailed: React.FC<InitFailedProps> = ({ errors, loading, setError, onRetry }) => {
    const { t } = useTranslation();

    return (
        <Stack flexDirection="column" gap={2} sx={{ p: 2 }}>
            {errors.map((error) => (
                <Alert severity="error" variant="outlined">
                    {`${t(error.message)}. (${error.code})`}
                </Alert>
            ))}
            <Stack flexDirection="row" gap={2}>
                <LoadingButton
                    loading={loading}
                    variant="contained"
                    onClick={() => {
                        onRetry();
                        setError && setError(null);
                    }}>
                    <T>retry</T>
                </LoadingButton>
                <Button href={paths.dashboard.root} variant="outlined">
                    <T>cancel</T>
                </Button>
            </Stack>
        </Stack>
    );
};
