// ----------------------------------------------------------------------

const ROOTS = {
    AUTH: "/auth",
    DASHBOARD: "/dashboard",
};

// ----------------------------------------------------------------------

export const paths = {
    // AUTH
    auth: {
        login: `${ROOTS.AUTH}/login`,
        register: `${ROOTS.AUTH}/register`,
    },
    // DASHBOARD
    dashboard: {
        root: ROOTS.DASHBOARD,
        ai: {
            root: `${ROOTS.DASHBOARD}/ai`,
        },

        access: {
            root: `${ROOTS.DASHBOARD}/access`,
        },
        config: {
            root: `${ROOTS.DASHBOARD}/config`,
        },
        keyValue: {
            root: `${ROOTS.DASHBOARD}/keyvalues`,
        },
        logs: {
            root: `${ROOTS.DASHBOARD}/logs`,
            detail: (id: number) => `${ROOTS.DASHBOARD}/logs/${id}`,
        },

        user: {
            root: `${ROOTS.DASHBOARD}/users`,
            new: `${ROOTS.DASHBOARD}/users/new`,
            edit: (id: number) => `${ROOTS.DASHBOARD}/users/edit/${id}`,
        },
    },
};
